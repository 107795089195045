import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import { GatsbyImage , getImage } from "gatsby-plugin-image";
import Layout from '../components/Layout';
import '../style/therapy.sass';
import PageIntroSection from '../components/PageIntroSection';
import useWindowSize from '../hooks/useWindowSize';
import { useLocation } from '@reach/router'
import UseGoToSection from '../components/UseGoToSection';
import humanSkeletonVideo from '../../static/videos/human-skeleton.mp4';
import { GlobalContext } from '../context/GlobalContext';


export const  Tabs = ({section , mainTab}) => {
  const location = useLocation();
  const title = (location.pathname.split('therapy/', 2)[1] ?? '').replace('-', ' ') ?? ''
  const [queryTitle, setTitle] = useState(title)
  const [activeTab , setActiveTab] = useState(queryTitle.length ? null : 0);
  const [childTab , setChildTab] = useState(0);
  const [childTab2 , setChildTab2] = useState(null);
  const [active , setactive] = useState(true);
  const { width } = useWindowSize();
  
  return (
    <div className = 'neurology-main-container section2'>
      <div className="tabs is-centered" style={{height:'3.4rem'}}>
        <ul style={{background: section.tabBG}}>
          {section.tabs.map((item,i) => (
            <li style={{width: width > 768 ? '17%': '100%'}} key={i} onClick={() => {setTitle(''); setActiveTab(i); setChildTab(0); setChildTab2(null);  }}>
              <a className={(i === activeTab) || (item.title.toLowerCase() === queryTitle) ? 'setTab' : ''} style={{width: '100%'}}>
                <span className={(i === activeTab) || (item.title.toLowerCase() === queryTitle) ? 'setFontColor tabs-list-title' : 'tabs-list-title'} key={i}>{item.title}</span>
              </a>
            </li>
          ))} 
        </ul>
      </div>
      {mainTab.map((t,no) => {
          const logo = getImage(t.logo)
          return(

         <div className = 'neurology-container-dad' style={{ display: queryTitle ? ( t.title.toLowerCase() === queryTitle ? 'flex' : 'none' ) : ( no === activeTab ? 'flex' : 'none' ) }} key={t.title}>
           <div className = 'neurology-container' >
              <div className = 'neurology-title-container'>
                <h3 className = 'has-text-weight-semibold neurology-container-main-title-heading'>{t.title}</h3>
                <p className='neurology-container-main-des'>{t.description}</p>
              </div>

              <div className = 'neurology-sub-container'>
                {t.array1.map((value,i2) => (
                <div className= {i2 === childTab ? 'setChildTab neurology-sub-container-child' : 'neurology-sub-container-child'} key={i2} onClick={() => {setChildTab(i2);}} style={{borderTop: `3px solid ${t.borderColor}`}}>
                  <div className = 'neurology-sub-container-child-logo'>
                    {logo ? (
                        <GatsbyImage image={logo} alt='Loading...'/>
                        ) : (
                        <img src={t.logo} alt='Loading...'/>
                    )}
                  </div>
                  <p className = 'neurology-sub-container-child-heading has-text-weight-semibold'>{value.heading}</p>
                </div>
                ))}
              </div>

              {t.subArray.map((para,paraIndex) => (
              <div className = 'neurology-sub-container-child2'  style={{ display: paraIndex === childTab ? 'flex' : 'none' }}key={paraIndex}>
                  {
                    para.diseaseDetails.map((sub, subIndex) => (
                      <div key={subIndex} style={{padding: "1% 0%"}}>
                        <p className = 'neurology-sub-container-child2-in-header'>{sub.subHeading}</p>
                        <p className = 'neurology-sub-container-child2-in'>{sub.paragraph}</p>
                      </div>
                    ))
                  }
              </div>
              ))}

              <div className = 'neurology-sub-container2'>
                {t.array2.map((value1,i1) => (
                <div className = {i1 === childTab2 ? active ? 'setChildTab neurology-sub-container-child-2' : 'neurology-sub-container-child-2 setChildTab2' : 'neurology-sub-container-child-2'} 
                key={i1} onClick={() => {setChildTab2(i1); setactive(active)}} style={{borderTop: `3px solid ${t.borderColor}`}}>
                  <div className = 'neurology-sub-container-child-logo'>
                    {logo ? (
                        <GatsbyImage image={logo} alt='Loading...'/>
                        ) : (
                        <img src={t.logo} alt='Loading...'/>
                    )}
                  </div>
                  <p className = 'neurology-sub-container-child-heading has-text-weight-semibold'>{value1.heading}</p>
                </div>
                ))}
              </div>

              {active && t.subArray1.map((para,paraIndex) => (
              <div className = 'neurology-sub-container-child2'  style={{ display: paraIndex === childTab2 ? 'flex' : 'none' }}key={paraIndex}>
                  {
                    para.diseaseDetails.map((sub, subIndex) => (
                      <div key={subIndex} style={{padding: "1% 0%"}}>
                        <p className = 'neurology-sub-container-child2-in-header'>{sub.subHeading}</p>
                        <p className = 'neurology-sub-container-child2-in'>{sub.paragraph}</p>
                      </div>
                    ))
                  }
              </div>
              ))}
              
            </div>
      </div>
      )})}
    </div>
  )
 
}


const TitleFragment = ({ fragment, color }) => (
  <span className='skeleton-title' style={{ color }}>{fragment + " "}</span>
);

const TitleFragment2 = ({ fragment, color }) => (
  <span className='skeleton-title2' style={{ color }}>{fragment + " "}</span>
);

const Skeleton = ({skeleton}) => {

  const { width } = useWindowSize();
  let backgroundImageData = getImage(skeleton.backgroundImgOne)
  let backgroundImageUrl = backgroundImageData ? backgroundImageData.images.fallback.src : skeleton.backgroundImage;
  const context = useContext(GlobalContext);
  const handleClick = async (e, newPath, newClass) => {
    await context.setState({ path: newPath, section: newClass });
  }

  return (
    <div className='section3'>
      <div className='skeleton-main-conatiner' style={{backgroundImage: `url(${backgroundImageUrl}),linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`
            ,backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}>
        <div className='skeleton-image-conatiner'>
          
          {skeleton.backgroundVideo && <video disablePictureInPicture className="skeleton-image" src={humanSkeletonVideo} autoPlay loop playsInline muted></video>}
        </div>
        <div className='skeleton-matter-conatiner'>
          <div className='skeleton-heading-title'>
              {skeleton.title.map((item,i) => (
                <TitleFragment key={i} {...item}/>
              ))}
          </div>
           
            <div className='skeleton-underline' style={{border: `1px solid ${skeleton.buttonColor}`}}></div>
            <p className='skeleton-help has-text-weight-medium' style={{color: skeleton.fontColor}}>We can help</p>
            <Link to="/contact"><button className='skeleton-buttton' onClick={(e) => handleClick(e, "/contact", "section2")} style={{background: skeleton.buttonColor , color: skeleton.fontColor, cursor: "pointer"}}>{skeleton.buttonLabel}</button></Link>
        </div>
      </div>
    </div>
  )
}

const SolveProblems = ({skeleton}) => {

  const { width } = useWindowSize();
  let backgroundImageData2 = getImage(skeleton.backgroundImgTwo)
  let backgroundImageUrl2 = backgroundImageData2 ? backgroundImageData2.images.fallback.src : skeleton.backgroundImageData2;

  const image2= getImage(skeleton.image2)

  return (
    <div className='section-3-sub'>
        <div className='part2-main-container' style={{backgroundImage: `url(${backgroundImageUrl2}),linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))`
           ,backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
          <div className='part2-container-sub'>
            <div className='part2-container-sub-title'>
              {skeleton.title2.map((value,i) => (
                <TitleFragment2 key={i} {...value}/>
              ))}
            </div>
            <div className='skeleton-underline' style={{border: `1px solid ${skeleton.buttonColor}`}}></div>
          </div>
          <div className='part2-container-sub-2'>
            <div className='part2-container-sub-2-img'> 
              {image2 ? (
              <GatsbyImage image={image2} alt='Loading...' className='skeleton-image2'/>
              ) : (
              <img src={skeleton.image2} alt='Loading...' className='skeleton-image2'/>
              )}
            </div>

            <div className='part2-container-sub-2-child'>
                <div className='part2-container-sub-2-child-1'>
                  <p className='part2-container-sub-2-child-1-p' style={{color: skeleton.fontColor}}>{skeleton.desOne}</p>
                  <Link to="/services"><button className='part2-container-sub-2-child-1-button' style={{background: skeleton.buttonColor , color: skeleton.fontColor, cursor: "pointer"}}>{skeleton.buttonLabel1}</button></Link>
                </div>
                <div className='part2-container-sub-2-child-1'>
                  <p className='part2-container-sub-2-child-1-p' style={{color: skeleton.fontColor}}>{skeleton.desTwo}</p>
                  <Link to="/products"><button className='part2-container-sub-2-child-1-button' style={{background: skeleton.buttonColor , color: skeleton.fontColor, cursor: "pointer"}}>{skeleton.buttonLabel2}</button></Link>
                </div>
            </div>
          </div>
      </div>
    </div>
  )
}

const TitleFragment3 = ({ fragment, color }) => (
  <span className='customer-title' style={{ color }}>{fragment + " "}</span>
);

const CustomerExp = ({customerExp}) => {
  const image1= getImage(customerExp.image1)
  const image2= getImage(customerExp.image2)

  return(
    <div className='customer-main-container section4'>
      <div className='customer-main-container-sub'>
        <div className='customer-main-container-sub-child-1'>
          <div className='customer-main-container-sub-chile-1-matter'>
              <p className='customer-main-container-sub-chile-1-matter-smalltitle'>{customerExp.smallTitle}</p>
              <p className='customer-main-container-sub-chile-1-matter-title' style={{color: customerExp.textColor}}>{customerExp.bigTitle}</p>
              <p className='customer-main-container-sub-chile-1-matter-p'>{customerExp.paragraph}</p>
          </div>
          <div className='customer-main-container-sub-chile-1-image'>
            {image1 ? (
            <GatsbyImage image={image1} alt='Loading...' className='customerExp-image'/>
            ) : (
            <img src={customerExp.image1} alt='Loading...' className='customerExp-image'/>
            )}
          </div>
        </div>

        <div className='customer-main-container-sub-child-2'>
          <div className='customer-main-container-sub-child-2-image'>
            {image2 ? (
            <GatsbyImage image={image2} alt='Loading...' className='customerExp-image'/>
            ) : (
            <img src={customerExp.image2} alt='Loading...' className='customerExp-image'/>
            )}
          </div>
          <div className='customer-main-container-sub-child-2-matter'>
            <div className='customer-main-container-sub-child-2-matter-title'>
            {customerExp.title.map((item,i) => (
                
                <TitleFragment3 key={i} {...item} />
                
            ))}
            </div>
              <p className='customer-main-container-sub-child-2-matter-p'>{customerExp.paragraph1}</p>
              <ul>
                {customerExp.list.map((list,index) => (
                  <li key={index} className='customer-main-container-sub-child-2-matter-li'>{list.value}</li>
                ))} 
              </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export const TherapyAreaExpertiseTemplate = ({pageIntroData , tabSection , skeleton , customerExp}) => {
  return (
    <div>
      <PageIntroSection pageIntro={pageIntroData}/>
      <Tabs section={tabSection} mainTab = {tabSection.arrayMain}/>
      <Skeleton skeleton={skeleton}/>
      <SolveProblems skeleton={skeleton}/>
      <CustomerExp customerExp={customerExp}/>
      <UseGoToSection />
    </div>
  )
}

TherapyAreaExpertiseTemplate.propTypes = {
  pageIntroData: PropTypes.shape({
    desktop: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired,
    mobile: PropTypes.shape({
      title: PropTypes.arrayOf(
        PropTypes.shape({
          fragment: PropTypes.string.isRequired,
          color: PropTypes.string.isRequired,
        })
      ).isRequired,
      backgroundVideo: PropTypes.string.isRequired,
      backgroundImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      description: PropTypes.string.isRequired,
      buttonLabel: PropTypes.string.isRequired,
      buttonColor: PropTypes.string.isRequired,
      buttonPath: PropTypes.string.isRequired,
      styles: PropTypes.object.isRequired
    }).isRequired
  }),
  tabSection: PropTypes.shape({
    tabs: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      })
    ).isRequired,
    tabBG: PropTypes.string.isRequired,
    arrayMain: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
        array1: PropTypes.arrayOf(
          PropTypes.shape({
            heading: PropTypes.string.isRequired,
          })
        ).isRequired,
        subArray: PropTypes.arrayOf(
          PropTypes.shape({
            paragraph: PropTypes.string.isRequired,
          })
        ).isRequired,
        array2: PropTypes.arrayOf(
          PropTypes.shape({
            heading: PropTypes.string.isRequired,
          })
        ).isRequired,
        subArray1: PropTypes.arrayOf(
          PropTypes.shape({
            paragraph: PropTypes.string.isRequired,
          })
        ).isRequired,
        borderColor: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
  skeleton: PropTypes.shape({
    backgroundImgOne: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundImgTwo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundVideo: PropTypes.string.isRequired,
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    fontColor: PropTypes.string.isRequired,
    buttonColor: PropTypes.string.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    title2: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    desOne: PropTypes.string.isRequired,
    desTwo: PropTypes.string.isRequired,
    buttonLabel1: PropTypes.string.isRequired,
    buttonLabel2: PropTypes.string.isRequired,
  }).isRequired,
  customerExp: PropTypes.shape({
    smallTitle: PropTypes.string.isRequired,
    bigTitle: PropTypes.string.isRequired,
    paragraph: PropTypes.string.isRequired,
    textColor: PropTypes.string.isRequired,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    title: PropTypes.arrayOf(
      PropTypes.shape({
        fragment: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    paragraph1: PropTypes.string.isRequired,
    list: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
}

const TherapyAreaExpertisePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <TherapyAreaExpertiseTemplate 
        pageIntroData={frontmatter.pageIntroData}
        tabSection={frontmatter.tabSection}
        skeleton={frontmatter.skeleton}
        customerExp={frontmatter.customerExp}
      />
    </Layout>
  );
};

TherapyAreaExpertisePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default TherapyAreaExpertisePage;


export const pageQuery = graphql`
  query TherapyAreaExpertisePage {
    markdownRemark(frontmatter: { templateKey: { eq: "therapy" } }) {
      frontmatter {
        pageIntroData {
          desktop {
            title {
              fragment
              color
            }
            backgroundVideo
            description
            buttonLabel
            buttonColor
            buttonPath
            styles {
              sectionHeight
              titleFontSize
              titleFontWeight
              titleLineHeight
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
              buttonPadding
              buttonFontSize
              buttonFontWeight
            }
          }
          mobile {
            title {
              fragment
              color
            }
            backgroundVideo
            description
            buttonLabel
            buttonColor
            buttonPath
            styles {
              sectionHeight
              titleFontSize
              descriptionFontSize
              descriptionFontWeight
              descriptionLineHeight
              contentWidth
              contentPadding
            }
          }
        }
        tabSection {
          tabs {
            title
          }
          tabBG
          arrayMain {
            title
            description
            logo {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
            array1 {
              heading
            }
            subArray{
              diseaseDetails {
                subHeading
                paragraph
              }
            }
            array2 {
              heading
            }
            subArray1{
              diseaseDetails {
                subHeading
                paragraph
              }
            }
            borderColor
          }
        }
        skeleton {
          backgroundImgOne {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          backgroundImgTwo {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          image1 {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          backgroundVideo
          title {
            fragment
            color
          }
          fontColor
          buttonColor
          buttonLabel
          title2 {
            fragment
            color
          }
          desOne
          desTwo
          buttonLabel1
          buttonLabel2
        }
        customerExp {
          smallTitle
          bigTitle
          paragraph
          textColor
          image1 {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          image2 {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
          title {
            fragment
            color
          }
          paragraph1
          list {
            value
          }
        } 
      }
    }
  }
`;
